<template>
  <v-container>
    <v-card class="mx-auto mt-5 text-center">
      <v-card-title>
        <h1 class="display-1 mx-auto">Dashboard</h1>
      </v-card-title>
      <v-card-text class="purple--text subtitle">
        <p>
          Welcome to your dashboard
          <strong class="text-uppercase success--text">{{ username }}</strong>
        </p>
        
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="logout">Logout</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",

  data: () => ({
    username: '',
  }),
  methods: {
    logout() {
      this.$router.push("/");
    }
  }
};
</script>
